<template>
    <select v-model="localSelectedKey">
        <option v-for="(value, key) in list" :key="key" :value="key">
            {{ value }}
        </option>
    </select>
</template>

<script>
export default {
    props:['list', 'selectedKey'],
    data() {
        return {};
    },
    computed: {
        localSelectedKey: {
            get() {
                return this.selectedKey
            },
            set(value) {
                this.$emit('change', value)
            }
        }
    },
    methods: {
        change(key) {
            this.$emit('change', key);
        },
    },
}
</script>
