<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <div class="image-form">
                            <input type="file" ref="fileInput" @change="handleFileUpload"/>
                            <key-value-dropdown
                                :list="dates"
                                :selected-key="selectedDateId"
                                @change="changeDate"
                            />
                            <p>{{ message }}</p>
                        </div>
                        <div class="tag-form mt-3">
                            <div class="form-group">
                                <label for="tag-name-jp">タグ日本語名</label>
                                <input type="text" id="tag-name-jp" class="form-control" v-model="nameJp">
                            </div>
                            <div class="form-group">
                                <label for="tag-name-en">タグ英語名</label>
                                <input type="text" id="tag-name-en" class="form-control" v-model="nameEn">
                            </div>
                            <div class="form-group">
                                <label for="tag-type">タグタイプ</label>
                                <key-value-dropdown
                                    :list="eroTagTypes"
                                    :selected-key="selectedEroTagType"
                                    @change="changeEroTagType"
                                />
                                <div class="form-group" v-if="Number(selectedEroTagType) === 0">
                                    <div class="form-group">
                                        <label for="tag-type-name-jp">タグタイプ日本語名</label>
                                        <input type="text" id="tag-type-name-jp" class="form-control" v-model="typeNameJp">
                                    </div>
                                    <div class="form-group">
                                        <label for="tag-type-name-en">タグタイプ英語名</label>
                                        <input type="text" id="tag-type-name-en" class="form-control" v-model="typeNameEn">
                                    </div>
                                    <div class="form-group">
                                        <label for="tag-type-order_no">順序</label>
                                        <input type="text" id="tag-type-order-no" class="form-control" v-model="typeOrderNo">
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn-primary" @click="addTag">追加</button>
                        </div>
                        <div class="mt-3">
                            <select-ero-tag-buttons
                                :title="'タグ設定'"
                                :selected-ero-tag-ids="selectedEroTagIds"
                                :ero-tags-per-type="eroTags"
                                @selectEroTagId="selectEroTagId"
                            ></select-ero-tag-buttons>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SelectEroTagButtons from '../molecules/SelectEroTagButtons'
import KeyValueDropdown from '../molecules/KeyValueDropdown'
import { RepositoryFactory } from '../../Repositories/RepositoryFactory'

const EroImageRepository = RepositoryFactory.get('EroImage')
const EroTagRepository = RepositoryFactory.get('EroTag')
const EroTagTypeRepository = RepositoryFactory.get('EroTagType')

export default {
    components: {
        SelectEroTagButtons,
        KeyValueDropdown
    },
    data() {
        return {
            file: null,
            nameJp: '',
            nameEn: '',
            typeOrderNo: 0,
            selectedEroTagIds: [],
            selectedDateId: '',
            eroTags: {},
            dates: {},
            message: '',
            eroTagTypes: {},
            selectedEroTagType: {},
            typeNameJp: '',
            typeNameEn: '',
        };
    },
    mounted() {
        this.getTags();
        this.getTagTypes();
    },
    methods: {
        handleFileUpload() {
            this.file = this.$refs.fileInput.files[0];
            this.message = '';
            this.uploadFile();
        },
        uploadFile() {
            let formData = new FormData();
            formData.append('image', this.file);
            formData.append('dateId', this.selectedDateId);
            formData.append('eroTagIds', JSON.stringify(this.selectedEroTagIds));

            EroImageRepository.store(formData)
            .then(response => {
                this.message = response.data.msg;
            })
            .catch(error => {

            });
        },
        addTag() {
            if (Number(this.selectedEroTagType) === 0) {
                this.addTagType();
            }
            let payload = {
                name_jp: this.nameJp,
                name_en: this.nameEn,
                ero_tag_type_id: this.selectedEroTagType,
            }
            EroTagRepository.store(payload)
            .then(response => {
                this.getTags();
            })
        },
        addTagType() {
            let payload = {
                name_jp: this.typeNameJp,
                name_en: this.typeNameEn,
                order_no: this.typeOrderNo
            }
            EroTagTypeRepository.store(payload)
            .then(response => {
                this.selectedEroTagType = response.data.id;
                this.$set(this.eroTagTypes, response.data.id, `${response.data.order_no}: ${response.data.name_jp}`);
            })
        },
        getTags() {
            EroTagRepository.index()
            .then(response => {
                this.eroTags = response.data.eroTags;
                this.dates = response.data.dates;
                const keys = Object.keys(this.dates);
                const lastKey = keys[keys.length - 1];
                this.selectedDateId = lastKey;
            })
        },
        selectEroTagId(id) {
            const index = this.selectedEroTagIds.indexOf(id);

            if (index === -1) {
                this.selectedEroTagIds.push(id);
            } else {
                this.selectedEroTagIds.splice(index, 1);
            }
        },
        changeDate(id) {
            this.selectedDateId = id;
        },
        changeEroTagType(id) {
            this.selectedEroTagType = id;
        },
        getTagTypes() {
            EroTagTypeRepository.index()
            .then(response => {
                response.data.forEach(element => {
                    this.eroTagTypes[element.id] = `${element.order_no}: ${element.name_jp}`;
                });
                this.eroTagTypes[0] = '新規';
                this.selectedEroTagType = 0;
            })
        },
    },
}
</script>
