<template>
    <div>
        <div v-if="isSearch" class="card mb-3">
            <div class="row border border-2 m-2">
                <div class="col">
                    <div class="row mt-2 mb-2">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <h4 class="ms-2 me-2 ps-2 border-bottom border-2">並び順</h4>
                                </div>
                            </div>
                            <div class="row ms-4 me-4">
                                <div class="col col-auto">
                                    <key-value-dropdown
                                        :list="orderTypes"
                                        :selected-key="localOrderType"
                                        @change="changeOrderType"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <h4 class="ms-2 me-2 ps-2 border-bottom border-2">検索期間</h4>
                                </div>
                            </div>
                            <div class="row ms-4 me-4">
                                <div class="col col-auto">
                                    <key-value-dropdown
                                        :list="dates"
                                        :selected-key="localFromDateId"
                                        @change="changeFromDate"
                                    />
                                </div>
                                <div class="col col-auto">
                                    ～
                                </div>
                                <div class="col col-auto">
                                    <key-value-dropdown
                                        :list="dates"
                                        :selected-key="localToDateId"
                                        @change="changeToDate"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <select-ero-tag-buttons
                            :title="'タグOR検索'"
                            :collapse-id="1"
                            :selected-ero-tag-ids="localTagIds"
                            :ero-tags-per-type="eroTags"
                        ></select-ero-tag-buttons>
                    </div>
                    <div class="row">
                        <select-ero-tag-buttons
                            :title="'タグ除外検索'"
                            :collapse-id="2"
                            :selected-ero-tag-ids="localNotTagIds"
                            :ero-tags-per-type="eroTags"
                        ></select-ero-tag-buttons>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-center mb-1">
                    <button class="btn btn-outline-success" @click="search"><i class="fas fa-search me-1"></i>検索</button>
                </div>
                <div class="col text-center mb-1">
                    <button class="btn btn-outline-success" @click="show"><i class="fas fa-search me-1"></i>スライドショー</button>
                </div>
            </div>
        </div>
        <div v-if="isSearch">
            <ero-images-slide-show
                ref="eroImagesSlideShow"
                :tag-ids="localTagIds"
                :not-tag-ids="localNotTagIds"
                :from-date-id="localFromDateId"
                :to-date-id="localToDateId"
                :order-type="localOrderType"
                :seed="localSeed"
                :limit="limit"
                @close="close"
            ></ero-images-slide-show>
        </div>
        <div class="card">
            <div class="row m-0">
                <div class="border border-1" v-for="(image, index) in eroImages" :key="image.id">
                    <ero-image
                        :id="image.id"
                        :date="image.date"
                        :image-path="eroImagePath + image.filename + '.' + extension"
                        :tags="image.tags"
                        @clickEroTagId="clickEroTagId"
                    ></ero-image>
                </div>
                <div v-if="isLoading">[読み込み中...]</div>
            </div>
        </div>
    </div>
</template>

<script>
const { nextTick } = Vue

import SelectEroTagButtons from '../molecules/SelectEroTagButtons'
import EroImage from '../molecules/EroImage'
import KeyValueDropdown from '../molecules/KeyValueDropdown'
import EroImagesSlideShow from './EroImagesSlideShow'
import { RepositoryFactory } from '../../Repositories/RepositoryFactory'
import VModal from "vue-js-modal";

Vue.use(VModal);

const EroImageRepository = RepositoryFactory.get('EroImage')
const EroTagRepository = RepositoryFactory.get('EroTag')

export default {
    props: {
        tagIds: {
            type: Array,
            default: function () {
                return []
            }
        },
        notTagIds: {
            type: Array,
            default: function () {
                return []
            }
        },
        fromDateId: {
            type: Number,
            default: 0
        },
        toDateId: {
            type: Number,
            default: 0
        },
        orderType: {
            type: Number,
            default: 1
        },
        seed: {
            type: Number,
            default: null
        },
        limit: {
            type: Number,
            default: 100
        },
        isSearch: {
            type: Boolean,
            default: false
        },
        orderTypes: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    components: {
        EroImage,
        SelectEroTagButtons,
        KeyValueDropdown,
        EroImagesSlideShow
    },
    data() {
        return {
            canNext: false,
            eroTags: {},
            eroImages: [],
            dates: {},
            eroImagePath: '',
            extension: '',
            isLoading: false,
            offset: 0,
            // 親で値を変えないのでcomputed使わずそのまま入れている
            localTagIds: this.tagIds,
            localNotTagIds: this.notTagIds,
            localFromDateId: this.fromDateId,
            localToDateId: this.toDateId,
            localOrderType: this.orderType,
            localSeed: this.seed,
        };
    },
    mounted() {
        this.getTags();
        this.getImages();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        getImages() {
            this.canNext = false;
            this.isLoading = true;
            const payload = {
                tagIds: this.localTagIds,
                notTagIds: this.localNotTagIds,
                fromDateId: this.localFromDateId,
                toDateId: this.localToDateId,
                offset: this.offset,
                orderType: this.localOrderType,
                seed: this.localSeed,
            };
            EroImageRepository.index(payload)
            .then(response => {
                this.canNext = response.data.images.length === this.limit;

                this.eroImages = this.eroImages.concat(response.data.images);
                this.eroImagePath = response.data.path;
                this.extension = response.data.extension;

                this.isLoading = false;
                this.offset = this.offset + 1;
            })
        },
        getTags() {
            EroTagRepository.index()
            .then(response => {
                this.eroTags = response.data.eroTags;
                let dates = response.data.dates;
                dates[0] = '未選択';
                this.dates = dates;
            })
        },
        handleScroll() {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 50 >= document.documentElement.offsetHeight;

            if (bottomOfWindow) {
                // スクロールが一番下まで達したときに行う処理
                if (this.canNext && !this.isLoading) {
                    this.getImages();
                }
            }
        },
        changeToDate(id) {
            this.localToDateId = Number(id);
        },
        changeFromDate(id) {
            this.localFromDateId = Number(id);
        },
        changeOrderType(id) {
            this.localOrderType = Number(id);
        },
        clickEroTagId(id) {
            if (this.isSearch) {

            } else {
                this.localTagIds = [id];
                this.search();
            }
        },
        search() {
            this.offset = 0;
            this.eroImages = [];
            this.localSeed = Math.floor( Math.random() * 1000 );
            this.getImages();
        },
        show() {
            this.$modal.show('modal-area');
            this.$refs.eroImagesSlideShow.search();
            this.$refs.eroImagesSlideShow.addSlideShowInterval();
        },
        close() {
            this.$modal.hide('modal-area');
            this.$refs.eroImagesSlideShow.removeSlideShowInterval();
        },
    },
}
</script>
