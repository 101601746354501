<template>
    <div>
        <div class="row">
            <div class="col ms-2"><span class="me-1">ID</span>:<span class="ms-1">{{id}}</span></div>
            <div class="col me-2 text-end"><span class="me-1">DATE</span>:<span class="ms-1">{{date}}</span></div>
        </div>
        <div class="row">
            <img :src="imagePath" class="ero-image">
        </div>
        <div class="row mt-1 pt-0">
            <div class="col">
                <ero-tag-buttons
                    :ero-tags="tags"
                    @clickEroTagId="clickEroTagId"
                ></ero-tag-buttons>
            </div>
        </div>
    </div>
</template>

<style>
.tag {
    font-size: 13px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 3px;
    padding-right: 3px;
}
.ero-image {
    max-height: 620px;
    object-fit: contain;
}
</style>

<script>
import EroTagButtons from '../molecules/EroTagButtons'

export default {
    props:['id', 'date', 'imagePath', 'tags'],
    components: {
        EroTagButtons,
    },
    methods: {
        clickEroTagId(id) {
            this.$emit('clickEroTagId', id);
        },
    },
}
</script>
