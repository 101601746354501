import EroImageRepository from './EroImageRepository'
import EroTagRepository from './EroTagRepository'
import EroTagTypeRepository from './EroTagTypeRepository'

const repositories = {
    EroImage: EroImageRepository,
    EroTag: EroTagRepository,
    EroTagType: EroTagTypeRepository
}

export const RepositoryFactory = {
    get: name => repositories[name]
}
