<template>
    <modal name="modal-area" :shift-x=0 :shift-y=0 :width="'100%'" :height="'100%'">
        <div class="row w-100 h-100 p-0 m-0"  style="background-color: black;">
            <div class="col col-0 slide-show-afi" ref="slideShowAfi" v-if="!isMobile">
            <!-- PC広告予定 -->
            </div>
            <div class="col col-12 h-100 p-0 m-0">
                <div class="row p-0 m-0" style="height: 0px;">
                    <div class="col p-0 m-0 slide-show-afi" ref="slideShowAfi" v-if="isMobile">
                    <!-- スマホ広告予定 -->
                    </div>
                </div>
                <div class="row p-0 m-0">
                    <div class="col h-100 p-0 m-0 text-center" v-if="currentEroImagePath !== ''">
                        <img :src="currentEroImagePath" :height="eroImageHeight" :width="eroImageWidth" class="p-0 m-0 ero-image-slide-show">
                    </div>
                </div>
                <div class="row w-100 justify-content-center align-items-center" ref="slideShowUtil" style="height: 40px;">
                    <div class="col col-auto ">
                        <key-value-dropdown
                            :list="seconds"
                            :selected-key="selectedSecondIndex"
                            @change="changeSecond"
                        />
                    </div>
                    <div class="col col-auto mb-1">
                        <button class="btn btn-outline-success" @click="close"><i class="fas fa-search me-1"></i>閉じる</button>
                    </div>
                </div>
            </div>
            <div class="col col-0 slide-show-afi" v-if="!isMobile">
            <!-- PC広告予定 -->
            </div>
        </div>
    </modal>
</template>

<style>
.ero-image-slide-show {
    object-fit: contain;
}
</style>

<script>

import { RepositoryFactory } from '../../Repositories/RepositoryFactory'
import KeyValueDropdown from '../molecules/KeyValueDropdown'

const EroImageRepository = RepositoryFactory.get('EroImage')

export default {
    props: {
        tagIds: {
            type: Array,
            default: function () {
                return []
            }
        },
        notTagIds: {
            type: Array,
            default: function () {
                return []
            }
        },
        fromDateId: {
            type: Number,
            default: 0
        },
        toDateId: {
            type: Number,
            default: 0
        },
        orderType: {
            type: Number,
            default: 1
        },
        seed: {
            type: Number,
            default: null
        },
        limit: {
            type: Number,
            default: 100
        },
    },
    components: {
        KeyValueDropdown,
    },
    data() {
        return {
            canNext: false,
            eroImages: [],
            eroImagePath: '',
            currentEroImagePath: '',
            currentIndex: 0,
            extension: '',
            isLoading: false,
            offset: 0,
            eroImageHeight: 0,
            eroImageWidth: 0,
            seconds: {3:'3秒', 4:'4秒', 5:'5秒', 6:'6秒', 7:'7秒', 8:'8秒', 9:'9秒', 10:'10秒', 11:'11秒', 12:'12秒', 13:'13秒', 14:'14秒', 15:'15秒', 16:'16秒', 17:'17秒', 18:'18秒', 19:'19秒', 20:'20秒'},
            selectedSecondIndex: 7,
            slideShowInterval: null
        };
    },
    mounted() {
    },
    beforeMount() {
        window.addEventListener('resize', this.onWindowResize)
        window.addEventListener('orientationchange', this.onWindowResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize)
        window.removeEventListener('orientationchange', this.onWindowResize)
    },
    computed: {
        isMobile: function () {
            const userAgent = window.navigator.userAgent.toLowerCase();
            if (
                userAgent.indexOf("iphone") != -1 ||
                userAgent.indexOf("ipad") != -1 ||
                userAgent.indexOf("android") != -1 ||
                userAgent.indexOf("mobile") != -1
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        getImages() {
            this.canNext = false;
            this.isLoading = true;
            const payload = {
                tagIds: this.tagIds,
                notTagIds: this.notTagIds,
                fromDateId: this.fromDateId,
                toDateId: this.toDateId,
                offset: this.offset,
                orderType: this.orderType,
                seed: this.localSeed,
            };
            EroImageRepository.index(payload)
            .then(response => {
                this.canNext = response.data.images.length === this.limit;
                this.eroImages.splice(0, this.currentIndex);
                this.currentIndex = 0;
                this.eroImages = this.eroImages.concat(response.data.images);
                this.eroImagePath = response.data.path;
                this.extension = response.data.extension;

                this.isLoading = false;
                this.offset = this.offset + 1;

                if (this.currentEroImagePath === '') {
                    this.currentEroImagePath = this.eroImagePath + this.eroImages[0].filename + '.' + this.extension;
                    this.onWindowResize();
                }
            })
        },
        handleNext() {
            if (this.eroImages.length - 3 <= this.currentIndex && !this.isLoading) {
                // 最後から2枚前に次の画像を取得
                if (!this.canNext) {
                    this.offset = 0;
                    this.localSeed = Math.floor( Math.random() * 1000 );
                }
                this.getImages();
            }
        },
        close() {
            this.$emit('close');
        },
        search() {
            this.offset = 0;
            this.eroImages = [];
            this.currentEroImagePath = '';
            this.localSeed = Math.floor( Math.random() * 1000 );
            this.getImages();
        },
        windowWidthWithoutScrollbar() {
            const { innerWidth } = window;
            const { clientWidth } = document.documentElement;

            if (innerWidth && clientWidth) {
                return Math.min(innerWidth, clientWidth);
            }

            return clientWidth || innerWidth;
        },
        onWindowResize() {
            if (this.$refs.slideShowUtil) {
                if (this.isMobile) {
                    this.eroImageHeight = window.innerHeight - this.$refs.slideShowUtil.clientHeight - this.$refs.slideShowAfi.clientHeight;
                    this.eroImageWidth = this.windowWidthWithoutScrollbar();
                } else {
                    this.eroImageHeight = window.innerHeight - this.$refs.slideShowUtil.clientHeight;
                    this.eroImageWidth = this.windowWidthWithoutScrollbar() - (this.$refs.slideShowAfi.clientWidth * 2);
                }
            }
        },
        changeSecond(id) {
            this.selectedSecondIndex = Number(id);
            this.removeSlideShowInterval();
            this.addSlideShowInterval();
        },
        addSlideShowInterval() {
            this.slideShowInterval = setInterval(() => {
                if ((this.currentIndex + 1) in this.eroImages) {
                    this.currentIndex++;
                    this.currentEroImagePath = this.eroImagePath + this.eroImages[this.currentIndex].filename + '.' + this.extension;
                }
                this.handleNext();
            }, this.selectedSecondIndex * 1000);
        },
        removeSlideShowInterval() {
            clearInterval(this.slideShowInterval)
        },
    },
}
</script>
