<template>
    <div class="tag-list">
        <button v-for="(tag, index) in eroTags" :key="tag.id" :class="{ btn: true, 'tag me-1 mb-1 btn-outline-dark': true}" @click="clickEroTagId(tag.id)">
            {{ tag.name_jp }}
        </button>
    </div>
</template>

<script>
export default {
    props: {
        selectedEroTagIds: {
            type: Array,
            default: function () {
                return []
            }
        },
        eroTags: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    methods: {
        isActive(id) {
            return this.selectedEroTagIds.indexOf(id) !== -1
        },
        clickEroTagId(id) {
            this.$emit('clickEroTagId', id);
        },
    },
}
</script>
