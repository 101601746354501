<template>
    <div class="">
        <h4 data-bs-toggle="collapse" role="button"
            v-bind:data-bs-target="'#collapse' + collapseId"
            v-bind:aria-controls="'collapse' + collapseId"
            aria-expanded="false"
            v-if="title !== ''" class="row border-bottom border-2 ms-2 me-2 mt-2 mb-2 collapsed">
                <span class="col col-auto pe-0">{{ title }}</span>
                <span class="col when-opened"><i class="fa-solid fa-chevron-up"></i></span>
                <span class="col when-closed"><i class="fa-solid fa-chevron-down"></i></span>
        </h4>
        <div>
            <div class="row ms-2 me-2 mt-1 mb-1">
                <button data-bs-toggle="button" v-for="(id) in selectedEroTagIds" :key="id" class="ms-1 me-1 mb-1 btn btn-outline-dark col-auto" @click="clickEroTag(id)">
                    <i class="me-1 far fa-times-circle"></i>{{ eroTags[id].name_jp }}
                </button>
            </div>
            <div class="row collapse" v-bind:id="'collapse' + collapseId">
                <div class="col border border-2 ms-4 me-4 mt-2 mb-2 pt-2 pb-2 ">
                    <div class="row">
                        <div class="tag-list col-auto">
                            <div v-for="(tags, type_name) in eroTagsPerType" :key="type_name">
                                <p class="p-0 m-0">{{ type_name }}</p>
                                <button data-bs-toggle="button" v-for="(tag, index) in tags" :key="tag.id" :class="{ 'p-1 pt-0 pb-0 ms-1 me-1 mb-1 btn btn-outline-dark': true, active: isActive(tag.id)}" @click="selectEroTagId(tag.id)">
                                    {{ tag.name_jp }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>

<script>
export default {
    props: {
        collapseId: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            default: ''
        },
        selectedEroTagIds: {
            type: Array,
            default: function () {
                return []
            }
        },
        eroTagsPerType: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    computed: {
        eroTags() {
            const eroTags = {};
            Object.keys(this.eroTagsPerType).map(typeName => {
                Object.keys(this.eroTagsPerType[typeName]).map(index => {
                    eroTags[this.eroTagsPerType[typeName][index]['id']] = this.eroTagsPerType[typeName][index];
                });
            });
            console.log(eroTags);
            return eroTags;
        }
    },
    methods: {
        isActive(id) {
            return this.selectedEroTagIds.indexOf(id) !== -1
        },
        selectEroTagId(id) {
            const index = this.selectedEroTagIds.indexOf(id);

            if (index === -1) {
                this.selectedEroTagIds.push(id);
            } else {
                this.selectedEroTagIds.splice(index, 1);
            }
        },
        clickEroTag(id) {
            const index = this.selectedEroTagIds.indexOf(id);
            if (index !== -1) {
                this.selectedEroTagIds.splice(index, 1);
            }
        }
    },
}
</script>
