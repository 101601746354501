import axios from 'axios'

axios.defaults.withCredentials = true
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

export default (resource) => {
  return {
    index: (payload = {}) => {
      return axios.get(`/api/${resource}`, {params: payload})
    },
    store: (payload) => {
      return axios.post(`/api/${resource}`, payload)
    },
    show: (id) => {
      return axios.get(`/api/${resource}/${id}`)
    },
    update: (id, payload) => {
      return axios.put(`/api/${resource}/${id}`, payload)
    },
    delete: (id) => {
      return axios.delete(`/api/${resource}/${id}`)
    }
  }
}